const INITIAL_STATE = {
  status: "loading",
  deals: [],
};

function dealsReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case 'FETCH_DEALS' : {
      return {
        status: "loaded",
        deals:  action.payload,
        selected: '',
      };
    }
    case 'NO_DEALS_FOUND' : {
      return {
        status: "nodealsfound",
        deals: [],
        selected: '',
      };
    }
    default : return state;
  }
}

export default dealsReducer;
